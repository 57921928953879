<template>
  <div class="comp-wrpr">
    <v-autocomplete-alt
      auto-select-first
      :rules="[allRules.required]"
      :label="
        label == null || label == ''
          ? `<i class='fad fa-file-contract mr-2'></i> Target Project Contract`
          : label
      "
      id="projectContractSelector"
      placeholder="Select a Project Contract"
      :value="value"
      @input="handleSelectedProjectContract"
      :items="contractsFiltered"
      :filter="projectContractFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :loading="isProjectContractsLoading"
      :disabled="isProjectContractsLoading"
      :readonly="readonly"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <strong class="fs-14px" :key="item.id + '_contractTemplateSelector'">{{
          data.item.name
        }}</strong>
      </template>
      <template #no-data>
        <h4 class="opacity-72 px-4 font-italic">No contracts to show!</h4>
      </template>
      <template #prepend-item v-if="showTypeFilter">
        <div
          class="d-inline-flex justify-end align-center elevation-1 white mb-2"
          style="
            position: sticky;
            top: 0px;
            z-index: 10;
            padding: 0.5rem;
            margin-top: -0.5rem;
            border-radius: 0 0 0.5rem 0;
          "
        >
          <span
            style="font-weight: 600; font-size: 14px; margin-right: 0.5rem; margin-left: 0.5rem"
          >
            Show
          </span>
          <v-btn-toggle rounded mandatory color="info darken-1" v-model="selectedType" dense>
            <v-btn :value="enums.ContractType.Proposal.value" small height="28px" class="px-3">
              <i class="fad mr-2" :class="enums.ContractType.Proposal.icon"></i>
              <span class="fs-12px font-weight-medium">{{ enums.ContractType.Proposal.text }}</span>
            </v-btn>
            <v-btn :value="enums.ContractType.Offer.value" small height="28px" class="px-3">
              <i class="fad mr-2" :class="enums.ContractType.Offer.icon"></i>
              <span class="fs-12px font-weight-medium">{{ enums.ContractType.Offer.text }}</span>
            </v-btn>
            <v-btn :value="enums.ContractType.ShiftRequest.value" small height="28px" class="px-3">
              <i class="fad mr-2" :class="enums.ContractType.ShiftRequest.icon"></i>
              <span class="fs-12px font-weight-medium">{{
                enums.ContractType.ShiftRequest.text
              }}</span>
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </v-autocomplete-alt>
  </div>
</template>

<script>
import contractService from "../services/contracts-service";
import enums from "../../../plugins/enums";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    showTypeFilter: {
      type: Boolean,
      default: false,
    },
    proposalsOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enums,
      selectedType: null,
      contracts: [],
      isProjectContractsLoading: false,
    };
  },
  computed: {
    contractsFiltered() {
      if (this.selectedType != null) {
        var filtered = this.contracts.filter((c) => c.type == this.selectedType);
        this.$log(">>>> contractsFiltered", filtered);
        return filtered;
      } else return this.contracts;
    },
  },
  methods: {
    handleSelectedProjectContract(val) {
      this.$emit("input", val);
    },
    getProjectContracts() {
      this.isProjectContractsLoading = true;
      contractService
        .query(this.projectId, { itemsPerPage: -1 })
        .then((resp) => {
          this.contracts = resp.data.items;
          this.$log("contracts", resp.data.items);
          this.isProjectContractsLoading = false;
        })
        .catch((err) => {
          this.isProjectContractsLoading = false;
          this.$handleError(err);
        });
    },
    projectContractFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.number == null ? "" : item.number.toLowerCase();
      const text3 = item.description == null ? "" : item.description.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    refresh() {
      this.getProjectContracts();
    },
  },
  created() {
    this.getProjectContracts();
  },
  mounted() {
    if (this.proposalsOnly) {
      this.selectedType = enums.ContractType.Proposal.value;
    }
  },
  watch: {
    proposalsOnly() {
      if (this.proposalsOnly) {
        this.selectedType = enums.ContractType.Proposal.value;
      }
    },
  },
};
</script>

<style lang="scss">
.comp-wrpr.space-templates-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.typeahead-space-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-space-description {
  max-width: calc(100% - 40px) !important;
}
</style>
