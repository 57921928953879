<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 500 : 1100"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Shift Request ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1 d-flex align-center" small>
        <span v-if="readonly">
          <i class="fad fa-arrows-turn-right mr-2"></i>View: {{ selected.name }}
        </span>
        <span v-else>
          <i class="fad fa-arrows-turn-right mr-2"></i>Update: {{ selected.name }}
        </span>
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="shiftRequestsUrl"
          title="Shift Request"
        ></entity-id>
        <resource-shift-request-state
          class="ml-2"
          :state="selected.state"
        ></resource-shift-request-state>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create an Shift Request
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Shift Request ...</p>
    </v-container>

    <v-form v-else v-model="slideouts.update.valid" ref="updateForm">
      <v-container v-if="slideouts.update.active" fluid class="pa-4">
        <v-row style="position: sticky; top: 0; z-index: 1">
          <v-col cols="12" sm="5" class="pb-0">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Name"
              placeholder="Shift Request Name"
              v-model="selected.name"
              readonly
            ></v-text-field-alt>
          </v-col>
          <v-col cols="12" sm="7" class="pt-1">
            <label class="input-label mt-1" style="margin-bottom: 6px">
              <i class="fad fa-user mr-2"></i> Shift hours for
              <span class="opacity-64 fs-12px mx-2">"Source"</span>
            </label>
            <user-all-selector
              :users="users"
              required
              hideLabel
              hideDetails
              :readonly="!managementMode || readonly"
              :isUsersLoading="isUsersLoading"
              v-model="selected.sourceUserId"
              @change="sourceUserChanged"
              style="width: 100%"
              class="ml-0 white"
            ></user-all-selector>
          </v-col>
          <v-col cols="12" md="12" class="mb-4">
            <rich-text-editor
              ref="richTextEditor"
              v-model="selected.justification"
              title="Shift Request Justification<i class='fas fa-star-of-life pink--text label-icon'></i>"
              showLabel
              allowExpand
              :readonly="readonly"
              maxHeight="120px"
            >
              <template v-slot:subtitle>
                <v-menu dense offset-y z-index="1000">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      color="secondary"
                      small
                      dark
                      class="px-2 ml-4"
                      :disabled="readonly || slideouts.update.isLoading"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="fas fa-flag mr-2"></i> Justification Examples
                      <i class="fas fa-caret-down ml-3 mr-1"></i>
                    </v-btn>
                  </template>
                  <v-list dense class="user-profile-menu-list">
                    <v-list-item
                      v-for="(template, i) in justificationTemplates"
                      @click="changeJustificationWith(template)"
                      :key="i"
                    >
                      <v-list-item-icon class="mr-2 justify-center align-center">
                        <i small class="secondary--text far fa-flag fs-16px"></i>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title color="secondary">
                          {{ template }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </rich-text-editor>
          </v-col>
        </v-row>
        <div class="mt-5 mb-0 d-flex flex-row align-center" style="gap: 1rem">
          <label class="input-label">
            <i class="fad fa-clock mr-2"></i>Shift Hours
            <v-chip
              color="secondary"
              dark
              class="ml-2 font-weight-bold"
              small
              label
              style="height: 20px"
              >{{ totalShiftedHours }}
            </v-chip>
          </label>
          <v-btn
            color="deep-purple"
            height="28px"
            max-height="28px"
            :dark="!readonly && selected.sourceUserId != null"
            :disabled="readonly || selected.sourceUserId == null"
            @click="openAddShiftRequestHours"
          >
            <i class="fad fa-plus-minus mr-2"></i><span class="fs-12px">Update Selection</span>
          </v-btn>
        </div>
        <div
          class="pa-5 inset-shadow mt-2"
          style="
            background: #eceff1;
            border-radius: 0.5rem;
            overflow: auto;
            overflow: overlay;
            height: 100%;
          "
        >
          <v-slide-y-reverse-transition mode="out-in" style="width: 100%">
            <div
              v-if="selected.shiftRecords.length == 0"
              key="empty"
              style="gap: 1rem"
              class="d-flex align-center justify-center flex-column py-4"
            >
              <i class="fad fa-calendar-pen" style="font-size: 7rem"></i>
              <h3 class="mb-1">Nothing requested yet!</h3>
              <v-btn
                color="deep-purple py-0"
                height="28px"
                max-height="28px"
                :dark="!readonly && selected.sourceUserId != null"
                :disabled="readonly || selected.sourceUserId == null"
                @click="openAddShiftRequestHours"
              >
                <i class="fad fa-plus-minus mr-2"></i><span class="fs-12px">Update Selection</span>
              </v-btn>
              <h4 v-if="selected.sourceUserId == null" class="mb-0 orange--text text--darken-2">
                <i class="fas fa-circle-exclamation mr-2"></i>Select Source User First!
              </h4>
            </div>
            <v-slide-y-transition
              v-else
              key="not-empty"
              mode="out-in"
              group
              style="width: 100%; gap: 1rem"
              class="d-flex flex-column"
            >
              <shift-request-record-card
                v-for="(shiftRequestRecord, i) in selected.shiftRecords"
                :key="'sr_' + i"
                :value="selected.shiftRecords[i]"
                :readonly="readonly"
                @delete="deleteShiftRequestRecord(shiftRequestRecord)"
                fullWidth
                allowDelete
              ></shift-request-record-card>
            </v-slide-y-transition>
          </v-slide-y-reverse-transition>
        </div>
        <add-shift-request-hours
          ref="addShiftRequestHours"
          v-model="selected"
          :users="users"
          :isUsersLoading="isUsersLoading"
          @close="isAddShiftRequestHoursPanelOpen = false"
          :management-mode="managementMode"
          nested
        ></add-shift-request-hours>
      </v-container>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && canSendToManagement">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="green"
                :loading="slideouts.update.isSending"
                :disabled="slideouts.update.isLoading || slideouts.update.hasChanges"
                @click="sendToManagement"
              >
                <i class="fad fa-swap-opacity fa-paper-plane mr-2"></i>
                Send To Management
              </v-btn>
            </template>
            <span class="d-flex align-center">Send this shift request to management!</span>
          </v-tooltip>
        </div>
        <v-divider vertical class="ml-4 mr-2"></v-divider>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            isMainRoute &&
            selected.state == enums.ResourceShiftRequestState.Draft.value &&
            $has(perms.ResourceShiftRequests.Update)
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !validShiftRecords ||
                  !validJustification ||
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !validShiftRecords ||
                  !validJustification ||
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchShiftRequest()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Shift Request</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1100" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="del"
              v-if="
                $has(perms.ResourceShiftRequests.Delete) &&
                selected.state == enums.ResourceShiftRequestState.Draft.value
              "
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="
                (!$has(perms.ResourceShiftRequests.Update) &&
                  !$has(perms.ResourceShiftRequests.Delete)) ||
                selected.state != enums.ResourceShiftRequestState.Draft.value
              "
              >No Actions Available!</v-subheader
            >
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import shiftRequestsAPI from "../services/resource-shift-requests-service";
import ShiftRequestRecordCard from "./ShiftRequestRecordCard.vue";
import ResourceShiftRequestState from "./ResourceShiftRequestState.vue";
import AddShiftRequestHours from "./AddShiftRequestHours.vue";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";

export default {
  name: "edit-shift-request",
  components: {
    PanelSizeControl,
    UserAllSelector,
    AddShiftRequestHours,
    ShiftRequestRecordCard,
    ResourceShiftRequestState,
    RichTextEditor,
  },
  data() {
    return {
      perms,
      enums,
      shiftRequestId: null,
      closeAfter: false,
      isFetching: false,
      readonly: false,
      isUsersLoading: false,
      isAddShiftRequestHoursPanelOpen: false,
      users: [],
      selected: {
        shiftRecords: [],
        justification: null,
      },
      selectedCemented: {},
      slideouts: {
        update: {
          valid: false,
          active: false,
          fullWidth: false,
          isLoading: false,
          isSending: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      justificationTemplates: [
        "Month-end Review; shift unused hours forward",
        "Exceeds RTM Hours; shift hours to current month",
        "Start Date Changed",
      ],
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    managementMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1100px";
    },
    shiftRequestsUrl() {
      if (this.selected == null) return "";
      return `shift-requests/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-shift-request") return true;
      else return false;
    },
    totalShiftedHours() {
      if (!this.slideouts.update.active) return 0;
      else
        return this.selected.shiftRecords.reduce((total, cur) => {
          return total + cur.shiftedHours;
        }, 0);
    },
    canSendToManagement() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return (
        this.$has(perms.ResourceShiftRequests.Update) &&
        this.selected.state == enums.ResourceShiftRequestState.Draft.value &&
        this.selected.sourceUserId == this.loggedInUser.id
      );
    },
    validShiftRecords() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return this.selected.shiftRecords.length > 0;
    },
    validJustification() {
      if (!this.slideouts.update.active || this.isFetching) return false;
      return (
        this.selected.justification != null &&
        this.selected.justification.trim() != "<p></p>" &&
        this.selected.justification.trim() != ""
      );
    },
  },
  created() {
    this.getAllUsersData();
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    changeJustificationWith(template) {
      this.selected.justification = `<p>${template}</p>`;
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.validShiftRecords &&
        this.validJustification &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.validShiftRecords &&
        this.validJustification &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.selected.state == enums.ResourceShiftRequestState.Draft.value &&
        this.$has(perms.ResourceShiftRequests.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode();
      }
    },
    sendToManagement() {
      this.$dialog
        .warning({
          text: `Are you sure you want to send this Shift Request to Management?<br/>
            <h4 class="fs-12px">${this.selected.name}</h4>
          `,
          title: `Send Shift Request to Management?`,
          color: "green",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "green",
              handle: () => {
                this.slideouts.update.isSending = true;
                return shiftRequestsAPI
                  .sendToManagement(this.selected.id)
                  .then((resp) => {
                    this.slideouts.update.isSending = false;
                    this.onsendToManagementSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Shift Request sent to Management successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.slideouts.update.isSending = false;
                    this.$dialog.notify.error("Error sending shift request!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onsendToManagementSuccess(id) {
      this.$emit("sent", id);
    },
    sourceUserChanged() {
      this.selected.shiftRecords = [];
    },
    openAddShiftRequestHours() {
      this.isAddShiftRequestHoursPanelOpen = true;
      this.$refs.addShiftRequestHours.open();
    },
    shiftRequestedHoursChanged(shiftRequestedHours, shiftRequest) {
      this.$log(">>>> shiftRequestedHoursChanged", shiftRequestedHours);
    },
    deleteShiftRequestRecord(shiftRequestRecord) {
      //remove
      var shiftRequestIdx = this.selected.shiftRecords.findIndex(
        (or) => or.id == shiftRequestRecord.id
      );
      this.selected.shiftRecords.splice(shiftRequestIdx, 1);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      if (this.isAddShiftRequestHoursPanelOpen) {
        // Avoid to close the inner Slide while outer mask clicked
        e.wait = true;
        return;
      }
      this.isAddShiftRequestHoursPanelOpen = false;

      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementShiftRequest();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewShiftRequest) {
      this.$emit("save", this.selected, isNewShiftRequest);
    },
    onResponse(resp, toSend) {
      this.slideouts.update.isLoading = false;
      var message = "Shift Request updated successfully!";
      this.$log(">>> updated", resp.data);
      this.selected = resp.data;
      this.cementShiftRequest();
      var isNewShiftRequest = false;
      if (!toSend.id) {
        isNewShiftRequest = true;
        this.shiftRequestId = this.selected.id;
        message = "Shift Request added successfully!";
      }
      this.announceChange(isNewShiftRequest);
      this.$dialog.notify.success(message, {
        position: "top-right",
        timeout: 3000,
      });
      this.readonly = true;
      this.checkForChanges();
      if (this.closeAfter) {
        this.closeUpdateSlideout();
      }
    },
    onCatch(err) {
      this.slideouts.update.isLoading = false;
      this.$handleError(err);
    },
    updateConfirmed(closeAfter) {
      this.closeAfter = closeAfter;
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      if (this.managementMode) {
        shiftRequestsAPI
          .updateByManagement(toSend)
          .then((resp) => this.onResponse(resp, toSend))
          .catch(this.onCatch);
      } else {
        shiftRequestsAPI
          .update(toSend)
          .then((resp) => this.onResponse(resp, toSend))
          .catch(this.onCatch);
      }
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    open(id, editMode = false) {
      this.$log(">>>>>>> open", id, editMode, this.loggedInUser);
      if (id == null) {
        this.selected = {
          sourceUserId: null,
          justification: null,
          shiftRecords: [], // {constructionPhaseRecordId, shiftedHours, shiftedToDate}
        };
        if (this.managementMode) this.selected.sourceUserId = null;
        else this.selected.sourceUserId = this.loggedInUser.id;

        this.readonly = false;
        this.isFetching = false;
        this.shiftRequestId = null;
        this.refreshName();
        this.cementShiftRequest();
      } else {
        this.readonly = !editMode;
        this.shiftRequestId = id;
        this.fetchShiftRequest();
      }
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.slideouts.update.tab = 0;
        this.$refs.updateForm.resetValidation();
      }, 250);
    },
    fetchShiftRequest() {
      this.isFetching = true;
      shiftRequestsAPI
        .getById(this.shiftRequestId)
        .then((resp) => {
          this.$log("getShiftRequestById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          for (let i = 0; i < this.selected.shiftRecords.length; i++) {
            const shiftRecord = this.selected.shiftRecords[i];
            shiftRecord.shiftedToDate = shiftRecord.shiftedToDate.substring(0, 7);
          }
          this.cementShiftRequest();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getShiftRequestById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementShiftRequest() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementShiftRequest()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      if (!this.validJustification) {
        setTimeout(() => {
          this.$refs.richTextEditor.focus();
        }, 100);
        return;
      }

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Shift Request?<br/><h4 class="fs-12px">${this.selected.name}</h4>`,
          title: `Delete Shift Request?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return shiftRequestsAPI
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteShiftRequestSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Shift Request deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Shift Request!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteShiftRequestSuccess(id) {
      this.$emit("delete", id);
    },
    refreshName() {
      var sourceName = "{{Source}}";
      if (this.selected.sourceUserId != null) {
        var sourceUser = this.users.find((u) => u.id == this.selected.sourceUserId);
        sourceName = `${sourceUser.firstName} ${sourceUser.lastName}`;
      }
      this.selected.name = `Shift hours for ${sourceName}`;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "selected.sourceUserId": {
      handler() {
        this.refreshName();
      },
      deep: true,
    },
  },
};
</script>
